<template>
  <v-card :loading="loaders.loading"
          flat
          class="d-flex flex-column fill-height"
  >
    <v-list  v-if="conversation.id" subheader>
      <v-list-item-group >
        <template >
          <v-list-item>
            <slot name="backward"></slot>
            <v-list-item-content>
              <v-list-item-title v-text="conversation.interlocutor.username"/>
              <v-list-item-subtitle v-text="'12-05-2020'"/>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon :color=" 'deep-purple accent-4' ">
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider
            class="my-0"
          />
        </template>
      </v-list-item-group>
    </v-list>

    <v-card-text class="flex-grow-1 overflow-y-auto">

      <v-sheet
        v-if="loaders.loading"
        class="mx-auto"
      >
        <v-row dense>
          <v-col
            class="d-flex child-flex"
            cols="12"
          >
            <v-card
              :color="`grey darken-2 `"
              class="pa-3"
            >
              <v-skeleton-loader
                class="mx-auto"
                :height="120"
                type="card"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>
      </v-sheet>


      <template v-for="(msg, i) in messagell.listEntity">
        <MessageItem :item="msg" :user="user" :user_id="user.id"></MessageItem>
      </template>
    </v-card-text>
    <v-card-text class="flex-shrink-1">
      <v-text-field
        v-model="messageForm.content"
        label="type_a_message"
        type="text" aria-multiline="true"
        no-details
        outlined
        :append-outer-icon="icons.mdiSend"
        @keyup.enter="send"
        @click:append-outer="send"
        hide-details
      />
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSend,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiExclamation,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import MessageItem from "@/components/messenger/MessageItem.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  components: {
    UserAvatar,
    MessageItem,
  },
  props: {
    conversation: Object,
    user_id: Number | String,
    user: Object,
  },
  setup(props) {

    const messages = ref([]);
    const messageForm = ref({});

    const dialog = ref({
      newmessage: false,
      imageviewer: false,
      authentication: false,
    })

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })

    const messagell = ref({})

    const loadMessage = next => {

      loaders.value.loading = true
      loaders.value.retry = false


      Drequest.api('lazyloading.message?dfilters=on&dsort=id asc')
        .param({
          'conversation.id:eq': props.conversation.id,
          per_page: 12,
          next,
          user_id: props.user_id,
          ///...props.messageoption,
        })
        .get(response => {
          console.log(response)
          loaders.value.loading = false
          if (next >= 2) {

            messagell.value.listEntity = [...messagell.value.listEntity, ...response.listEntity]
            messagell.value.next = response.next

          } else {
            loaders.value.loading = false
            messagell.value = response

          }
        })
        .fail(e => {
          loaders.value.loading = false
          loaders.value.retry = true
          console.log(e)
        })

    }
    loadMessage(1)

    const deleted = (index) => {
      messagell.value.listEntity.splice(index, 1)
    }

    const infiniteScrolling = (entries, observer, isIntersecting) => {
      console.log(loaders.value.loading)

      if (loaders.value.loading) return

      console.log(messagell.value.next >= 2, messagell.value)
      // messagell.value.next >= 2 &&
      if (messagell.value.remain) loadMessage(messagell.value.next)

    }
    const send = () => {

      messagell.value.listEntity.push({
        'user': props.user,
        'content': messageForm.value.content,
        'conversation_id': props.conversation.id,
      })

      setTimeout(() => messageForm.value = {}, 100)

    }

    /*const interval = setInterval(()=>{
      console.log('sent request')
    }, 9000)

    console.log(interval)*/

    return {
      infiniteScrolling,
      send,
      deleted,

      messagell,
      messageForm,
      loaders,

      icons: {
        mdiClose,
        mdiExclamation,
        mdiSend,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
