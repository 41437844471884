<script>

import {computed, ref} from "@vue/composition-api";
import {useRouter} from "@/utils";
import {Drequest} from "@/plugins/Drequest";
import MessengerComponent from "@/components/messenger/MessengerComponent.vue";
import {
  mdiArrowRight, mdiChevronLeft,
  mdiClose, mdiCreditCardOutline, mdiDotsHorizontal,
  mdiChat, mdiEyeOffOutline,
  mdiArrowLeft,
  mdiSend,
  mdiArrowHorizontalLock,
  mdiSquareEditOutline
} from "@mdi/js";
import UserAvatar from "@/components/UserAvatar.vue";
import BottomNavigation from "@/layouts/components/BottomNavigation.vue";
import AppBarUserMenu from "@/layouts/components/AppBarUserMenu.vue";
import ThemeSwitcher from "@/layouts/components/ThemeSwitcher.vue";
import VerticalNavMenu from "@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue";
import {getVuetify} from "@/plugins/utils";

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher, AppBarUserMenu, BottomNavigation, UserAvatar, MessengerComponent
  },
  setup() {
    const $vuetify = getVuetify()

    const isDrawerOpen = ref(false);
    const activeChat = ref(null);
    const responsiveChat = ref(true);
    const conversationll = ref({});
    const conversation = ref({});


    const breakpoint = computed(() => {
      if ($vuetify.breakpoint.sm) {
        return {
          hidecover: true
        }
      }
      if ($vuetify.breakpoint.xs) {
        return {
          hidecover: true
        }
      }

      return {
        hidecover: false
      }
    })

    const user_id = ((localStorage.getItem('user_id')))
    const user = ref(Drequest.getUser())
    const {router} = useRouter()
    const route = router.currentRoute
    const id = parseInt(route.params.id)

    const init = (item) => {
      conversation.value = item
      activeChat.value = item.id
      if (breakpoint.value.hidecover)
        responsiveChat.value = false

      Drequest.api('detail.conversation?id=' + item.id + '&user_id=' + user_id)
        .get((response) => {
          console.log(response)
          conversation.value = response.conversation
        })

    }
    Drequest.api('conversation.index?dfilters=on&next=1&per_page=15&jsonmodel=recap&user_id=' + user_id)
      .get((response) => {
        console.log(response)
        conversationll.value = response
      })

    if (id) {
      init({id: id, interlocutor: {}})
    }

    const responsive = () => {
      if (breakpoint.value.hidecover){
        conversation.value = {}
        activeChat.value = null
      }

        responsiveChat.value = !responsiveChat.value
    }

    return {
      init,
      responsive,

      breakpoint,
      isDrawerOpen,
      conversationll,
      activeChat,
      conversation,
      user,
      responsiveChat,
      icons: {
        mdiClose,
        mdiChat,
        mdiSend,
        mdiSquareEditOutline,
        mdiArrowLeft,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
        mdiArrowHorizontalLock,
      },
    }
  }
}

</script>

<template>
  <v-app class="">

    <vertical-nav-menu
      :user="user"
      :user_id="user.id"
      :is-drawer-open.sync="isDrawerOpen"
    ></vertical-nav-menu>
    <v-app-bar  v-if="responsiveChat || (!activeChat && breakpoint.hidecover)"
      app
      density="compact"
      elevation="0"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-lg-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-xs-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          >
            <v-img
              width="30"
              contain
              alt=""
              :src="require('@/assets/images/avatars/logo.png')"
            ></v-img>
          </v-app-bar-nav-icon>

          <v-app-bar-title>Reader
          </v-app-bar-title>
          <v-spacer></v-spacer>

          <theme-switcher class="mr-3"></theme-switcher>
          <app-bar-user-menu
            :user="user"
            :user_id="user.id"
          ></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-row :class="{'no-gutters': true, ' mt-15' : responsiveChat || (!activeChat && breakpoint.hidecover) } ">
      <v-col v-if="responsiveChat || (!activeChat && breakpoint.hidecover)"
             cols="12" sm="3"
             class="flex-grow-1 flex-shrink-0"
             style="border-right: 1px solid #0000001f;"
      >
        <v-responsive class="overflow-y-auto fill-height">
          <v-list subheader>
            <v-list-item-group>
              <v-list-item
                :key="`parent`"
              >
                <v-list-item-content>
                  <v-list-item-title v-text="'Messagerie'"/>
                  <v-list-item-subtitle v-text="''"/>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                :key="`chatDivider`"
                class="my-0"
              />
            </v-list-item-group>
            <v-list-item-group v-model="activeChat">
              <template v-for="(item, index) in conversationll.listEntity">
                <v-list-item
                  :key="`parent${index}`"
                  :value="item.id"
                  @click="init(item)"
                >

                  <v-list-item-avatar color="grey lighten-1 white--text">

                    <user-avatar :size="40" :user="item.interlocutor"></user-avatar>

                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.interlocutor.username"/>
                    <v-list-item-subtitle v-text="item.lastmessage.content"/>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon :color="item.active ? 'deep-purple accent-4' : 'grey'">
                      {{ icons.mdiChat }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-divider
                  :key="`chatDivider${index}`"
                  class="my-0"
                />
              </template>
            </v-list-item-group>
          </v-list>
        </v-responsive>
      </v-col>
      <v-col v-if="!(!activeChat && breakpoint.hidecover)"
        cols="auto"
        class="flex-grow-1 flex-shrink-0"
      >
        <v-responsive
          v-if="activeChat"
          class="overflow-y-hidden fill-height"
        >

<!--          <v-list subheader>
            <v-list-item-group>
              <v-list-item @click="responsiveChat = !responsiveChat"
              >
                <v-list-item-avatar color="grey lighten-1 ">
                  <v-icon>{{ icons.mdiArrowHorizontalLock }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-text="''"/>
                  <v-list-item-subtitle v-text="''"/>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                class="my-0"
              />
            </v-list-item-group>
          </v-list>-->
          <MessengerComponent :conversation="conversation" :user="user"
                              :user_id="user.id">
            <template #backward>

              <v-btn  @click="responsive"  icon ><v-icon >{{ icons.mdiArrowLeft }}</v-icon></v-btn>

              <v-list-item-avatar  color="grey lighten-1 white--text">
                <span>
                <user-avatar :size="30" :user="conversation.interlocutor"></user-avatar>
                </span>
              </v-list-item-avatar>

            </template>
          </MessengerComponent>
        </v-responsive>
      </v-col>
    </v-row>
    <!--
          <bottom-navigation></bottom-navigation>-->
  </v-app>
</template>

<style scoped lang="scss">

</style>
