<template>

        <div
          :class="{ 'd-flex flex-row-reverse': message.me }"
        >
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-hover
                v-slot:default="{ hover }"
              >
                <v-chip
                  :color="message.me ? 'primary' : 'info'"
                  dark
                  style="height:auto;white-space: normal;"
                  class="pa-4 mb-2"
                  v-on="on"
                >
                  {{ message.content }}
                  <sub
                    class="ml-2"
                    style="font-size: 0.5rem;"
                  >{{ message.created_at }}</sub>
                  <v-icon
                    v-if="hover"
                    small
                  >
                    {{icons.mdiExpandAllOutline}}
                  </v-icon>
                </v-chip>
              </v-hover>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>delete</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiVuetify,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiSquareEditOutline,
  mdiEyeOffOutline,
  mdiChevronLeft,
  mdiDotsHorizontal,
  mdiExclamation,
  mdiExpandAllOutline
} from '@mdi/js'
import {onMounted, ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'

export default {
  components: {
  },
  props: {
    item: Object,
    user_id: Number|String,
    user: Object,
  },
  setup(props) {

    const loaders = ref({
      config: false,
      loading: false,
      retry: false,
    })
    const message = ref({})
    onMounted(()=>{
      message.value = props.item
      message.value.me = props.user_id == props.item.user.id

      if (!message.value.id)
        send()
    })
    const send = ( ) => {

      Drequest.api('create.message')
        .data({
          message:{
            'conversation.id': message.value.conversation_id,
            'user.id': props.user_id,
            'content': message.value.content,
          }
        })
        .raw(response =>{
          console.log(response)
          message.value = {
            ... message.value,
            ... response.message
          }
        })

    }

    return {
      send,

      message,
      loaders,

      icons: {
        mdiClose,
        mdiExclamation,
        mdiVuetify,
        mdiSquareEditOutline,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiDotsHorizontal,
        mdiExpandAllOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';

.av {
  border: 5px solid var(--v-secondary-base);
}

.av-active {
  border: 5px solid var(--v-primary-base);
}

.v-item-group.v-bottom-navigation--absolute, .v-item-group.v-bottom-navigation--fixed {
  z-index: 4000;
}
</style>
